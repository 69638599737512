"use client";

import { Modal, ModalContent } from "@nextui-org/modal";
import { useDisclosure } from "@nextui-org/use-disclosure";
import { createContext, ReactNode, startTransition, useCallback, useContext, useRef, useState, useTransition } from "react";
// import OTPInput from "react-otp-input";
import { OTPInput, SlotProps } from "input-otp";
import Image from "next/image";
import { Button } from "../custom";
import { VerifySecurityPin } from "@/lib/actions";
import clsx from "clsx";
import { useTranslations } from "next-intl";
import { cn } from "@nextui-org/react";

interface SecurityPinContextType {
  openSecurityPinModal: () => Promise<string>;
  closeSecurityPinModal: (failed?: boolean, reason?: string) => void;
}

const SecurityPinContext = createContext<SecurityPinContextType>({} as SecurityPinContextType);
// Inspired by Stripe's MFA input.
function FakeDash() {
  return (
    <div className="flex w-10 items-center justify-center">
      <div className="bg-border h-1 w-3 rounded-full" />
    </div>
  );
}

// Feel free to copy. Uses @shadcn/ui tailwind colors.
function Slot(props: SlotProps) {
  return (
    <div
      className={cn(
        // "relative w-full h-auto aspect-square",
        "flex items-center justify-center",
        "transition-[outline] duration-300 ease-in-out",
        "relative mx-1 aspect-square !h-auto !w-12 rounded-lg bg-white text-center text-lg",
        // "border-border border-y border-r first:rounded-l-md first:border-l last:rounded-r-md",
        // "group-focus-within:border-black/20 group-hover:border-black/20",
        "outline outline-[0.0625rem] outline-black/20",
        { "outline-[0.125rem] outline-yellow-400": props.isActive }
      )}
    >
      <div className="group-has-[input[data-input-otp-placeholder-shown]]:opacity-20">
        {props.char ?? props.placeholderChar}
      </div>
      {props.hasFakeCaret && <FakeCaret />}
    </div>
  );
}
// You can emulate a fake textbox caret!
function FakeCaret() {
  return (
    <div className="animate-caret-blink pointer-events-none absolute inset-0 flex items-center justify-center">
      <div className="h-8 w-px bg-white" />
    </div>
  );
}
export const SecurityPinProvider = ({ children }: { readonly children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [otp, setOtp] = useState("");
  const [invalid, setInvalid] = useState(false);
  const resolveRef = useRef<(value: string | PromiseLike<string>) => void>();
  const rejectRef = useRef<(reason?: any) => void>();
  const t = useTranslations();
  const openModal: () => Promise<string> = () => {
    onOpen();
    return new Promise<string>((resolve, reject) => {
      resolveRef.current = resolve;
      rejectRef.current = reject;
    });
  };

  const closeModal = (failed?: boolean, reason?: string) => {
    if (rejectRef.current && failed) {
      rejectRef.current(reason);
      rejectRef.current = undefined;
    } else if (resolveRef.current) {
      resolveRef.current(reason || "Modal closed");
      resolveRef.current = undefined;
    }
    onClose();
  };

  // useEffect(() => {
  //   document.addEventListener("keydown", (e) => {
  //     console.log(e.key);

  //     if (e.key === "o") {
  //       console.log("Open Modal");
  //       openModal().then(() => console.log("Modal Closed"));
  //     }
  //   });
  // }, []);

  const [pending] = useTransition();

  const onSubmit = useCallback(
    () =>
      startTransition(async () => {
        const res = await VerifySecurityPin(otp);
        if ("errors" in res) {
          console.error(res);
          setOtp("");
          setInvalid(true);
          return;
        }
        setOtp("");
        closeModal(false, res.data.tmp_user);
      }),
    [otp]
  );

  return (
    <SecurityPinContext.Provider value={{ openSecurityPinModal: openModal, closeSecurityPinModal: closeModal }}>
      {children}
      <Modal
        isOpen={isOpen}
        onClose={() => closeModal(true, "Modal closed")}
        size="sm"
        scrollBehavior="inside"
        classNames={{
          body: "bg-[#021517]",
          header: "bg-[#0A2A27] shadow-lg rounded-t-lg",
          closeButton: clsx("hover:bg-white/5 active:bg-white/10"),
          wrapper: "max-h-none",
        }}
        className="z-[101]"
        placement="center"
        radius="lg"
      >
        <ModalContent className="max-h-[80vh]">
          <div className="mx-auto flex w-full max-w-80 flex-col items-center gap-2 p-2 py-6">
            {/* <div className="flex w-full items-center justify-start">
              <Link href="/login">
                <BsFillArrowLeftCircleFill size={40} color="#D8D8D8" />
              </Link>
            </div> */}
            <div className="flex items-center justify-center">
              <Image src={"/assets/img/auth/success-new-password.png"} alt="Security Pin" width={80} height={80} />
            </div>
            <div>
              <div className="text-center text-[24px] font-bold">{t("security-verification")}</div>
              {/* <div className="text-center text-xs text-[#8f8f8f]">
                We've sent a six digits code verification to {searchParams.get("email")}.
              </div> */}
            </div>
            <div
              className="flex flex-col gap-2"
              onFocus={() => {
                setInvalid(false);
              }}
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                containerClassName="group flex items-center has-[:disabled]:opacity-30 min-w-80"
                maxLength={6}
                render={({ slots }) => (
                  <>
                    <div className="flex w-full">
                      {slots.map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div>
                    {/* <div className="flex">
                      {slots.slice(0, 3).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div>

                    <FakeDash />

                    <div className="flex">
                      {slots.slice(3).map((slot, idx) => (
                        <Slot key={idx} {...slot} />
                      ))}
                    </div> */}
                  </>
                )}
              />
              {/* <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                // renderSeparator={<div className="w-2" />}
                renderInput={(props) => (
                  <input
                    {...props}
                    className="mx-1 aspect-square !h-auto !w-12 rounded-lg border bg-white text-center text-lg focus:!outline-yellow-400"
                  />
                )}
              /> */}
              {invalid && <span className="text-center text-tiny text-danger">{t("security-pin.error")}</span>}
            </div>
            {/* {!expired ? (
              <span className="my-4 text-sm">
                OTP expires in{" "}
                {
                  <Countdown
                    ref={countdownRef}
                    onComplete={() => setExpired(true)}
                    renderer={({ minutes, seconds }) => {
                      return (
                        <span>
                          {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                        </span>
                      );
                    }}
                    date={date}
                  />
                }
              </span>
            ) : (
              <>
                <div className="my-4 text-sm">
                  Didn't receive the email?{" "}
                  <span
                    onClick={() => onResend()}
                    className="cursor-pointer text-primary underline hover:text-[#ffe68a]"
                  >
                    Resend OTP
                  </span>
                </div>
              </>
            )} */}
            <Button
              onPress={onSubmit}
              className="mt-4 w-full font-bold text-black disabled:bg-slate-400"
              isDisabled={otp.length !== 6 || pending}
            >
              {t("submit")}
            </Button>
          </div>
        </ModalContent>
      </Modal>
    </SecurityPinContext.Provider>
  );
};

export const useSecurityPin = () => {
  const context = useContext(SecurityPinContext);
  if (context === undefined) {
    throw new Error("useSecurityPin must be used within a SecurityPinProvider");
  }
  return context;
};
