"use client";

import _ from "lodash";
import { useEffect, useState, useTransition } from "react";
import { GetWalletBalance, MT5Deposit } from "@/lib/actions";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import { Button } from "@/components/custom";
import { Form, Input, Spacer } from "@nextui-org/react";
import { useSecurityPin } from "@/components/modals/security-pin";
import { useTranslations } from "next-intl";
import useSwalModal from "@/app/context/useSwalModal";
import { useRouter } from "@/i18n/routing";
import { LuArrowUpDown } from "react-icons/lu";
import { PiCurrencyDollarBold } from "react-icons/pi";
import { numberFormat } from "@/lib/helper";
import { useRefresh } from "@/app/context/useRefresh";
dayjs.extend(LocalizedFormat);

const MT5DepositForm = ({
  onClose,
  encryptedId,
  accountId,
  accountBalance,
}: {
  onClose?: () => void;
  encryptedId?: string;
  accountId?: string;
  accountBalance?: number;
}) => {
  const { refresh } = useRefresh();
  const { OpenNormalModal } = useSwalModal();

  const [wallet, setWallet] = useState<number>();

  const [pending, startTransition] = useTransition();
  const [submitting, startSubmitting] = useTransition();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [amount, setAmount] = useState<string>();
  const t = useTranslations();

  const { openSecurityPinModal } = useSecurityPin();

  useEffect(() => {
    startTransition(async () => {
      try {
        const wallet = (await GetWalletBalance()) as Record<string, string>;
        setWallet(Number(wallet["1"]));
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const ActionHandler = (formData: FormData) => {
    const { amount } = Object.fromEntries(formData.entries());

    if (Number(amount) > wallet!) {
      setErrors({
        amount: t("mt5-transfer-invalid.insufficient", {
          balance: wallet?.toLocaleString(),
        }),
      });
      return;
    }

    if (Number(amount) < 10) {
      setErrors({
        amount: t("mt5-transfer-invalid.minimum", {
          amount: 10,
        }),
      });
      return;
    }

    openSecurityPinModal().then((tmpUser) => {
      startSubmitting(async () => {
        const res = await MT5Deposit(encryptedId!, formData.get("amount") as string, tmpUser);
        if ("errors" in res) {
          setErrors(res.errors);
          return;
        }

        if (res) {
          OpenNormalModal(
            t("mt5-transfer-success.title"),
            <>
              {/* You have successfully transfer $ {amount}.00 from USDT wallet to MT5 Account ({accountId}). */}
              {t("mt5-deposit-success.message", {
                amount: Number(amount),
                accountId,
              })}
            </>
          ).then(() => {
            onClose?.();
            refresh();
          });

          // location.reload();
        }
      });
    });
  };

  return (
    <Form className="size-full space-y-4" action={ActionHandler}>
      <Input
        size="lg"
        label={t("usdt-balance")}
        startContent={<PiCurrencyDollarBold size={16} />}
        readOnly
        classNames={{
          base: "!pointer-events-none [&_*]:pointer-events-none",
          label: "!pointer-events-none",
        }}
        labelPlacement="outside"
        value={wallet ? numberFormat(wallet) : "0.00"}
      />
      <LuArrowUpDown className="!my-4 mx-auto" size={24} />
      <Input
        size="lg"
        startContent={<PiCurrencyDollarBold size={16} />}
        label={
          <>
            {t("mt5-account")} <span className="me-2 text-tiny">({accountId})</span>
          </>
        }
        readOnly
        classNames={{
          base: "!pointer-events-none [&_*]:pointer-events-none",
          label: "!pointer-events-none",
        }}
        labelPlacement="outside"
        value={accountBalance ? numberFormat(accountBalance) : "0.00"}
      />
      <Spacer y={1} />
      <Input
        size="md"
        label={t("amount")}
        labelPlacement="outside"
        name="amount"
        startContent={<></>}
        placeholder={t("enter-deposit-amount")}
        type="number"
        classNames={{
          inputWrapper: "bg-transparent",
        }}
        errorMessage={errors.amount}
        isRequired
        isInvalid={!!errors.amount}
        value={amount}
        onValueChange={setAmount}
      />
      <div className="flex w-full items-center justify-center gap-4">
        {[20, 100, 200, 500].map((value) => (
          <Button
            onPress={() => {
              setAmount((prev) => String(Number(prev || 0) + value));
            }}
            key={value}
            className="w-full font-bold text-black"
            variant="bordered"
            isIconOnly
            fullWidth
          >
            +{value}
          </Button>
        ))}
      </div>
      <Button className="bg-[#F6C649] text-black" fullWidth type="submit">
        {t("create-deposit")}
      </Button>
    </Form>
  );
};

export default MT5DepositForm;
