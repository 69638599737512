"use client";

"use client";

import { createContext, useContext, useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";
import { v4 } from "uuid";
type RefreshType = {
  refresh: () => void;
  refreshToken: string;
};

const RefreshContext = createContext<RefreshType | undefined>(undefined);

export function RefreshProvider({ children }: { children: React.ReactNode }) {
  const [hash, setHash] = useState<string>(v4());

  return (
    <RefreshContext.Provider
      value={{
        refresh: () => setHash(v4()),
        refreshToken: hash,
      }}
    >
      {children}
    </RefreshContext.Provider>
  );
}

export function useRefresh() {
  const context = useContext(RefreshContext);
  if (!context) {
    throw new Error("useRefresh must be used within a UAProvider");
  }
  return context;
}
